<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
    <g clip-path="url(#clip0_1047:15528)">
      <path
        d="M2.41925 27.3932L3.8305 30.1181C4.12375 30.6919 4.54525 31.1426 5.04012 31.4705C6.45762 29.4591 7.44412 27.9155 8.00012 26.84C8.56425 25.7485 9.25762 24.041 10.0802 21.7179C7.86325 21.3916 6.18325 21.2285 5.04025 21.2285C3.94312 21.2285 2.26312 21.3916 0 21.7179C0 22.353 0.146625 22.9881 0.439875 23.5619L2.41925 27.3932V27.3932Z"
        fill="#0066DA"
      />
      <path
        d="M26.9602 31.4705C27.4552 31.1426 27.8767 30.6919 28.1699 30.1182L28.7564 28.9914L31.5606 23.5619C31.8484 23.0005 32 22.3649 32.0004 21.7179C29.7241 21.3916 28.0472 21.2285 26.9695 21.2285C25.8112 21.2285 24.1342 21.3916 21.9385 21.7179C22.7514 24.0537 23.4356 25.7612 23.9912 26.84C24.5516 27.9284 25.5412 29.4718 26.9602 31.4705V31.4705Z"
        fill="#EA4335"
      />
      <path
        d="M16.0001 10.2443C17.6401 8.03018 18.7703 6.32272 19.3907 5.12224C19.8903 4.15553 20.4402 2.61198 21.0402 0.491738C20.5453 0.163913 19.9772 0 19.3907 0H12.6095C12.023 0 11.455 0.184454 10.96 0.491738C11.7232 2.92346 12.371 4.65411 12.903 5.68356C13.491 6.82131 14.5233 8.34152 16.0001 10.2443Z"
        fill="#00832D"
      />
      <path
        d="M21.9198 21.7178H10.08L5.04004 31.4704C5.53479 31.7982 6.10291 31.9621 6.68941 31.9621H25.3104C25.8969 31.9621 26.4652 31.7777 26.9599 31.4702L21.9199 21.7178H21.9198Z"
        fill="#2684FC"
      />
      <path
        d="M16.0001 10.2446L10.9601 0.492188C10.4651 0.820013 10.0436 1.27067 9.75037 1.84443L0.439875 19.8744C0.152016 20.4357 0.000380309 21.0714 0 21.7184H10.0802L16.0002 10.2446H16.0001Z"
        fill="#00AC47"
      />
      <path
        d="M26.905 10.8595L22.2496 1.84443C21.9565 1.27067 21.5349 0.820013 21.04 0.492188L16 10.2448L21.9198 21.7185H31.9818C31.9818 21.0833 31.8351 20.4483 31.5419 19.8745L26.905 10.8595Z"
        fill="#FFBA00"
      />
    </g>
    <defs>
      <clipPath id="clip0_1047:15528">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "AddressIcon",
};
</script>
